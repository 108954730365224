import { useState, useEffect } from "react";
import TemplatePage from "./App";
import CustomButton from "./Custom/CustomButton";
import { Table, Accordion, Form, InputGroup, Card, CardBody } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomTextrare from "./Custom/CustomTextrate";
import CustomTextbox from "./Custom/CustomTextbox";
import Cookies from 'js-cookie';

type Replacements = {
    rep_bef: string,
    rep_aft: string,
    rep_ruby: string,
    rep_ruby_h: string,
};

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// localStorageに保存するための関数
const saveResArrayToLocalStorage = (repArray: Replacements[]) => {
    localStorage.setItem('repArray', JSON.stringify(repArray));
};

// localStorageから取得するための関数
const getResArrayFromLocalStorage = (): Replacements[] => {
    const repArrayString = localStorage.getItem('repArray');
    if (repArrayString) {
        return JSON.parse(repArrayString) as Replacements[];
    }
    return [];
};

const Changer = () => {
    const [repList, setRepList] = useState<Replacements[]>([]);

    // IDを削除する関数
    const handleRemoveId = (index: number) => {
        const updatedIdList = [...repList];
        updatedIdList.splice(index, 1); // 指定されたindexの要素を削除
        setRepList(updatedIdList);
        saveResArrayToLocalStorage(updatedIdList);
    };

    const [baseText, setBaseText] = useState('');
    const [replText, setReplText] = useState('');

    const handleSave = () => {
        let rubys = `<ruby>${baseText}<rt>${replText}</rt></ruby>`;
        let rubys_h = `|${baseText}《${replText}》`;
        let saveList = [...repList, { rep_bef: baseText, rep_aft: replText, rep_ruby: rubys, rep_ruby_h: rubys_h }];
        setRepList([...repList, { rep_bef: baseText, rep_aft: replText, rep_ruby: rubys, rep_ruby_h: rubys_h }]);
        setBaseText('');
        setReplText('');

        saveResArrayToLocalStorage(saveList)
    };

    // 変更するテキストに関するフック
    const [text, setText] = useState('');
    const [repText, setRepText] = useState('');
    const [h_repText, h_setRepText] = useState('');

    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // 入力欄に入力されたものをリアルタイムに処理する
    const handleTextChange = (value: string) => {
        setText(value);
        let repCon = replaceContent(value);
        let h_reoCon = H_replaceContent(value);
        setRepText(repCon);
        h_setRepText(h_reoCon);

        setCookie('inputRubyBase', encodeURIComponent(value), 30);
    };

    // 簡易特殊タグをHTML対応のタグに変換してVIEWに表示する処理
    function replaceContent(text: string): string {
        let output = text;
        repList.forEach(({ rep_bef, rep_ruby }) => {
            output = output.replace(new RegExp(rep_bef, 'g'), rep_ruby);
        });
        return output;
    }

    function H_replaceContent(text: string): string {
        let output = text;
        repList.forEach(({ rep_bef, rep_ruby_h }) => {
            output = output.replace(new RegExp(rep_bef, 'g'), rep_ruby_h);
        });
        return output;
    }

    // ハーメルン対応のコードをコピーする処理
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    const onClick_Copy = () => {
        copyToClipboard(h_repText);
    }

    const rubyListSetUp = (list: Replacements[]) => {
        setRepList([...repList, ...list]);
    }

    useEffect(() => {
        const storedRubyList = getResArrayFromLocalStorage();
        rubyListSetUp(storedRubyList);

        const storedRubyText = Cookies.get('inputRubyBase');
        if(storedRubyText){
            let texts = decodeURIComponent(storedRubyText);
            setText(texts);
        }

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }
    }, []);

    return (
        <TemplatePage>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>登録一覧</Accordion.Header>
                    <Accordion.Body>
                        <Table striped bordered hover className="my-2">
                            <thead>
                                <tr>
                                    <th>対象</th>
                                    <th>ルビ</th>
                                    <th>削除</th>
                                </tr>
                            </thead>
                            <tbody>
                                {repList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.rep_bef}</td>
                                        <td>{item.rep_aft}</td>
                                        <td>
                                            <CustomButton onClick={() => handleRemoveId(index)} variant="danger">X</CustomButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p />
            <InputGroup>
                <InputGroup.Text>変換対象/ルビ</InputGroup.Text>
                <Form.Control value={baseText} onChange={(e) => setBaseText(e.target.value)} aria-label="text" />
                <Form.Control value={replText} onChange={(e) => setReplText(e.target.value)} aria-label="ruby" />
            </InputGroup>
            <CustomButton onClick={handleSave} variant="primary" className="mt-2">Save</CustomButton>
            <p />

            <Card>
                <CardBody>
                    <CustomTextbox height={viewSize + "px"} value={repText} />
                </CardBody>
            </Card>
            <p />
            <CustomTextrare height={inputViewSize + "px"} value={text} onChange={handleTextChange} />
            <p />
            <CustomButton variant='warning' onClick={onClick_Copy}>コピー</CustomButton>
        </TemplatePage>
    );
}

export default Changer;
