import React from "react";
import { Button, ButtonProps as BootstrapButtonProps } from "react-bootstrap";

interface ButtonProps extends BootstrapButtonProps {
  width?: string;
}

const CustomButton = (props: ButtonProps) => {
  const { width, children, ...rest } = props;

  return (
    <Button className="mb-3" style={{ width: width }} {...rest}>
      {children}
    </Button>
  );
};

export default CustomButton;