import React from 'react';
import Form from 'react-bootstrap/Form';

interface CustomSelectProps {
    options: { value: string; label: string; }[];
    onChange:(value: string) => void;
    selectedValue?: string; // 新しく追加する選択された値のプロパティ
    labelView?: boolean;
    label?: string;
    disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onChange, selectedValue, labelView, label, disabled }) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(e.target.value);
    };

    return (
        <Form.Select className="mb-3" aria-label="選択してください" onChange={handleChange} disabled={disabled}>
            {labelView && <option>{label}</option>}
            {options.map(option => (
                <option key={option.value} value={option.value} selected={option.value === selectedValue}>
                    {option.label}
                </option>
            ))}
        </Form.Select>
    );
}

export default CustomSelect;
