import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

interface CustomFormProps {
    onChange: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    value: string;
    height?: string;
    label?: string;
    disabled?: boolean;
}

const CustomForm: React.FC<CustomFormProps> = ({ onChange, onKeyDown, value, height = "150px", label="Comments",disabled}) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    return (
        <>
            <FloatingLabel controlId="floatingTextarea" label={label} className="mb-3">
                <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: height }}
                    onChange={handleChange}
                    onKeyDown={onKeyDown} // Add the onKeyDown event here
                    value={value}
                    disabled={disabled}
                />
            </FloatingLabel>
        </>
    );
};

export default CustomForm;