import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';

interface CustomResNumProps {
    onChange: (value: string) => void;
    values: string;
}

const CustomResNum: React.FC<CustomResNumProps> = ({ onChange, values }) => {
    const [value, setValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // 数字以外の文字を削除する
        const newValue = e.target.value.replace(/\D/g, '');
        setValue(newValue);
        onChange(newValue); // 親コンポーネントに値を渡す
    };

    return (
        <Form.Control
            className="mb-3"
            as="textarea"
            placeholder="1"
            style={{ height: '50px', fontSize: '20px' }}
            value={values}
            onChange={handleChange}
        />
    );
};

export default CustomResNum;
