import React, { forwardRef } from 'react';

interface CustomTextboxProps {
    value: string;
    size?: string;
    height?: string;
}

const CustomTextbox: React.ForwardRefRenderFunction<HTMLDivElement, CustomTextboxProps> = ({ value, size = "16pt", height = "250px" }, ref) => {
    return (
        <div 
            style={{ 
                height: height, 
                fontSize: size, // フォントサイズをピクセル単位で指定
                overflowY: 'scroll', 
                padding: '10px', 
                whiteSpace: 'pre-wrap' 
            }} 
            dangerouslySetInnerHTML={{ __html: value }} 
            ref={ref}
        />
    );
};

export default forwardRef(CustomTextbox);