import React, { useState, useEffect, useRef } from 'react';
import TemplatePage from "./App";
import Tabs from "./Custom/Tabs";
import { Alert, Form, Offcanvas } from 'react-bootstrap';
import CustomTextbox from "./Custom/CustomTextbox";
import CustomForm from "./Custom/BBS/CustomForm";
import CustomRange from './Custom/BBS/CustomRange';
import CustomSelect from './Custom/BBS/CustomSelect';
import CustomPic from './Custom/BBS/CustomPic';
import Cookies from 'js-cookie';
import CustomButton from './Custom/CustomButton';
import { generateRandomString, RES_Union, RES_Union_H } from './Custom/BBS/Option'
import CustomResNum from './Custom/BBS/CustomResNum';

type RES_Content = {
    res_no: number; // レス番号
    res_name: string; // レス名
    res_id: string; // レスID
    res_text: string; // レス内容
};

interface Option {
    value: string;
    label: string;
}

const getOptionsNameCookie = (): Option[] => {
    const nameListCookie = Cookies.get('nameList');
    if (nameListCookie) {
        const nameList = JSON.parse(decodeURIComponent(nameListCookie)) as string[];
        return nameList.map((name, index) => ({
            value: name,
            label: name
        }));
    }
    return [];
};

const getOptionsIdCookie = (): Option[] => {
    const idListCookie = Cookies.get('idList');
    if (idListCookie) {
        const idList = JSON.parse(decodeURIComponent(idListCookie)) as string[];
        return idList.map((id, index) => ({
            value: id,
            label: id
        }));
    }
    return [];
};

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

// localStorageに保存するための関数
const saveResArrayToLocalStorage = (resArray: RES_Content[]) => {
    localStorage.setItem('resArray', JSON.stringify(resArray));
};

// localStorageから取得するための関数
const getResArrayFromLocalStorage = (): RES_Content[] => {
    const resArrayString = localStorage.getItem('resArray');
    if (resArrayString) {
        return JSON.parse(resArrayString) as RES_Content[];
    }
    return [];
};

const TestEdit = () => {
    const [options, setOptions] = useState<Option[]>([]);
    const [options_id, setOptions_id] = useState<Option[]>([]);
    const [seleted_id, setSelected_id] = useState<Option[]>([]);

    const [title, setTitle] = useState(``);
    const [text, setText] = useState('');
    const [repText, setRepText] = useState('');
    const [h_repText, h_setRepText] = useState('');
    const [F_size, setFsize] = useState(`20px`);
    const [selectedOption, setSelectedOption] = useState('ななしの競馬民');
    const [pickerCol, setPickerCol] = useState('#e0e0e0');
    const [chatId, setChatId] = useState(generateRandomString(10));
    const [switchState, setIsSwitchOn] = useState(true);
    const [resNo, setResNo] = useState(1);
    const repTextRef = useRef<HTMLDivElement>(null);
    const hRepTextRef = useRef<HTMLDivElement>(null);

    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // レス内容入力した際のhandle
    const handleTextChange = (value: string) => {
        setText(value);
    };

    //　スレタイトル入力した際のhandle
    const handleTitleChange = (value: string) => {
        setTitle(value);
        const newResArray = newResArrayAdd(1);
        submitUnion(value, newResArray);
    };

    const handleRangeChange = (value: string) => {
        let size = value;
        setFsize(size);
    };

    const handlePicChange = (value: string) => {
        setPickerCol(value);
    }

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleIdsChange = (value: string) => {
        setChatId(value);
    };

    const handleResNoChange = (value: string) => {
        setResNo(parseInt(value))
    }

    const submitBase = () => {
        const newResArray = newResArrayAdd();
        submitUnion(title, newResArray);

        // localStorageに保存
        saveResArrayToLocalStorage(newResArray);

        let resNewNo = resNo + 1;

        setCookie('resTitle', encodeURIComponent(title), 30);
        setCookie('resNewNo', encodeURIComponent(resNewNo.toString()), 30);

        setResNo(resNo + 1);
        let random_Id = generateRandomString(10);
        setChatId(random_Id);
        setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
        setText("");
    }

    const submitUnion = (titles: string, resArray: RES_Content[]) => {
        let allText = RES_Union(titles, pickerCol, resArray, F_size, switchState);
        let allCode = RES_Union_H(titles, pickerCol, resArray, switchState);
        setRepText(allText);
        h_setRepText(allCode);
    }

    // 新規のレスを追加
    const newResArrayAdd = (check: number = 0) => {
        // localStorageから既存のresArrayを取得
        const existingResArray = getResArrayFromLocalStorage();

        // 新しいレスを既存のresArrayに追加
        let newResArray = existingResArray;
        if (check === 0) {
            newResArray = [...existingResArray, { res_no: resNo, res_name: selectedOption, res_id: chatId, res_text: text }];
        }

        return newResArray;
    }

    const onClick = () => {
        submitBase();
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    const onClick_Copy = () => {
        copyToClipboard(h_repText);
    }

    const onClick_Reset = () => {
        const userConfirmed = window.confirm('リセットすると復元できません。\nCookieも削除されますがよろしいですか？');
        if (userConfirmed) {
            // ユーザーがOKをクリックした場合の処理
            let titles = title;

            setResNo(1);
            let random_Id = generateRandomString(10);
            setChatId(random_Id);
            setSelected_id([{ value: random_Id, label: random_Id }, ...options_id])
            setText("");

            setRepText(``);
            h_setRepText(``);

            // localStorageのリセット
            localStorage.removeItem('resArray');

            console.log(`タイトル：${titles} はリセットされました。`);
            // ここにリセット処理を記述します
        } else {
            // ユーザーがキャンセルをクリックした場合の処理
            alert(`リセットがキャンセルされました。`);
        }
    };

    /** ここから先はキーイベントに関する処理 */

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // 例: Ctrl+Sが押されたときに何かをする
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            submitBase();
        }
    };
    /** ここまでがキーイベントに関する処理 */


    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [repText]);

    useEffect(() => {
        if (hRepTextRef.current) {
            hRepTextRef.current.scrollTop = hRepTextRef.current.scrollHeight;
        }
    }, [h_repText]);

    // localStorageに保存した情報を取得し表示する処理
    useEffect(() => {
        const storedResArray = getResArrayFromLocalStorage();
        submitUnion(title, storedResArray);

        const optionsNameCookie = getOptionsNameCookie();
        setOptions(optionsNameCookie);

        const optionsIdCookie = getOptionsIdCookie();
        setOptions_id(optionsIdCookie);
        setSelected_id([{ value: chatId, label: chatId }, ...optionsIdCookie]);

        const savedSwitchState = Cookies.get('switchState');
        if (savedSwitchState !== undefined) {
            setIsSwitchOn(decodeURIComponent(savedSwitchState) === 'true');
        }

        const storedResTitle = Cookies.get('resTitle');
        const storedResNewNo = Cookies.get('resNewNo');

        if (storedResTitle) {
            let titles = decodeURIComponent(storedResTitle);
            setTitle(titles);
        }
        if (storedResNewNo) {
            let newNo = decodeURIComponent(storedResNewNo);
            setResNo(Number(newNo));
        }

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');

        if (storedViewSize) {
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        } else {
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if (storedInputViewSize) {
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        } else {
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if (storedPreviewShowState !== undefined) {
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        } else {
            setPreviewShow(true);
        }
    }, []);

    const TABS = [
        {
            id: 'view_text',
            title: 'VIEW',
            content: <CustomTextbox height={viewSize + "px"} value={repText} size={F_size} ref={repTextRef} />,
        },
        {
            id: 'h_text',
            title: 'CODE',
            content: <CustomTextbox height={viewSize + "px"} size={F_size} value={h_repText} />,
        },
    ];

    return (
        <TemplatePage>
            <CustomButton variant="primary" onClick={handleShow}>
                特殊タグをプレビューする
            </CustomButton>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>特殊タグのプレビュー画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div dangerouslySetInnerHTML={{ __html: repText }} ref={repTextRef} />
                </Offcanvas.Body>
            </Offcanvas>

            {!previewShow && <p style={{ color: "red" }}>※プレビュー画面の固定化：無効</p>}
            {previewShow && <p style={{ color: "green" }}>※プレビュー画面の固定化：有効</p>}
            {previewShow && <Tabs tabs={TABS} />}
            {previewShow && <br />}

            <CustomForm height='50px' label="Title" onChange={handleTitleChange} value={title} />
            <div className="row">
                <div className="col">
                    <CustomResNum onChange={handleResNoChange} values={resNo.toString()} />
                </div>
                <div className="col">
                    <CustomSelect options={options} onChange={handleSelectChange} />
                </div>
                <div className="col">
                    <CustomSelect options={seleted_id} selectedValue={chatId} onChange={handleIdsChange} />
                </div>
                <div className='col'>
                    <CustomPic onChange={handlePicChange} />
                </div>
            </div>
            {/* ↓は文字サイズ変更が利かないためコメントアウト */}
            {/*<CustomRange onRangeChange={handleRangeChange} />*/}
            <CustomForm height={inputViewSize + "px"} onChange={handleTextChange} onKeyDown={handleKeyDown} value={text} />
            <CustomButton width='100%' onClick={onClick}>投稿</CustomButton>
            <div className='row'>
                <div className='col'>
                    <CustomButton variant="warning" width='100%' onClick={onClick_Copy}>コピー</CustomButton>
                </div>
                <div className='col'>
                    <CustomButton variant="danger" width='100%' onClick={onClick_Reset}>リセット</CustomButton>
                </div>
            </div>
        </TemplatePage>
    );
};

export default TestEdit;