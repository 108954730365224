import React from 'react';
import Form from 'react-bootstrap/Form';

interface CustomPicProps {
    onChange: (value: string) => void;
}

const CustomPic: React.FC<CustomPicProps> = ({ onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    return (
        <>
            <Form.Control
                className="mb-3"
                type="color"
                id="exampleColorInput"
                defaultValue="#e0e0e0"
                title="Choose your color"
                onChange={handleChange}
            />
        </>
    );
};

export default CustomPic;