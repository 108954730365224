// 掲示板の投稿内容を格納する配列のアイテム名
type RES_Content = {
    res_no: number; // レス番号
    res_name: string; // レス名
    res_id: string; // レスID
    res_text: string; // レス内容
};

type Chat_Content = {
    res_check: number; // 1: 自分, 2: 相手 
    res_user: string; // 名前
    res_text: string; // 内容
    res_read: number; // 0: 未読, 1: 既読
}

// IDに使用する乱数生成
export function generateRandomString(length: number): string {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@+-";
    let result = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset[randomIndex];
    }
    return result;
}

// view用に配列を操作する
export function RES_Union(title: string, picCol:string, resArray: RES_Content[],size:string = `18px`, switchs:boolean = true,) {
    const viewCss = {
        'background-Color': `${picCol}`,
        'font-Size': `${size}`,
        padding: '5px'
    }

    const cssString = Object.keys(viewCss).map(key => `${key}:${viewCss[key as keyof typeof viewCss]}`).join(';');
    const header = `<div style="${cssString}">`;
    const footer = `</div>`;

    const titles = `<h2>${title}</h2>`;

    let text = "";

    if(switchs == true){
        for (let res of resArray) {
            text += `<p>${res.res_no}: <span style="color:#008000">${res.res_name}</span> ID:${res.res_id}</p><p>${res.res_text}</p>`;
        }
    }else{
        for (let res of resArray) {
            text += `<p>${res.res_no}: ${res.res_name}</p><p>${res.res_text}</p>`;
        }
    }

    const RES_All = `${header}
${titles}
${text}
${footer}`;
    return RES_All;
}

// ハーメルン用に配列を操作する
export function RES_Union_H(title:string, picCol:string, resArray: RES_Content[], switchs:boolean = true,){
    const header =
    `《boxbgcolor:${picCol}》
《box:p0.5》`;
    const footer =
    `《/box》
《/boxbgcolor》`;

    let titles = `《text:s1.5》${title}《/text》`;

    let text = "";

    if(switchs == true){
        for (let res of resArray) {
            text += 
            `${`《id:r${res.res_no}》`}${res.res_no}: 《color:#008000》${res.res_name}《/color》 ID:${res.res_id}
${res.res_text}

${`《id:r${res.res_no}e》`} 
`;
        }
    }else{
        for (let res of resArray) {
            text += 
            `${`《id:r${res.res_no}》`}${res.res_no}: 《color:#008000》${res.res_name}《/color》
${res.res_text}

${`《id:r${res.res_no}e》`} 
`;
        }
    }

    let RES_All =
    `${header}
${titles}

${text}
${footer}`
return RES_All;
}

export function text_Union(title: string, picCol: string, resArray: RES_Content[], size: string = `18px`, switchs: boolean = true,) {
    let resText = ``;

    if (switchs == true) {
        for(let res of resArray){
            resText += `${res.res_no}: ${res.res_name} ID:${res.res_id}
${res.res_text}

`;
        }
    }

    let allText = `${title}

${resText}`;


    return allText;
}

// Chat用の処理
export function Chat_Union(chatTitle: string, chatContent: Chat_Content[]) {
    let header = `<div className="container mt-3">
    <div class="tag_box" style="background-Color: #273246; color: #202020; border: 1px solid #273246;">
        <p id="187"></p>
        <div class="text-center text-white" style="color: white;">${chatTitle}</div>
        <p id="188"></p>
        <div class="tag_box" style="background-Color: #7494c0; color: #202020; padding: 0.5em;">
            <div class='container'>`;

    let footer = `</div></div>
        <p id="203"></p>
        <div class="tag_box" style="background-Color: #ffffff; color: #202020; padding: 0.5em;">
            <div class="text-start">
                <span class="tag_text" style="font-Size: 1em; background-Color: #ffffff;">＋</span>
            </div>
            <div style="background-Color: #e6e6fa;">　</div>
        </div>
        <p id="204"></p>
    </div>
</div>`;

    let chatText = ``;

    for(let chat of chatContent){
        // 1: 自分, 2: 相手
        if(chat.res_check == 1){
            chatText += `<div class="row">
                    <div class="text-end" style="word-Wrap: break-word; position: relative; text-Align: right;">
                        <div style="word-Wrap: break-word; max-Width: 80%; float: right; background-Color: #8de055; border-Radius: 0.5em; padding: 2px 10px">
                                    ${chat.res_text}
                                    </div>
                                </div>
                            </div>`;

            // 0: 未読, 1: 既読
            if(chat.res_read == 1){
                chatText += `<div class='row'>
                    <div class='text-end'><span style="font-Size: 12px">既読</span></div>
                </div>`;
            }
        }else{
            chatText += `<div class="row">
                    <div style="position: relative; height: 1.7em; text-Align: left; font-Size: 12px;">${chat.res_user}</div>
                </div>
                <div class="row">
                    <div style="position: relative; text-Align: left;">
                        <div style="float: left; background-Color: #ffffff; border-Radius: 0.5em; padding: 2px 10px">
                            ${chat.res_text}
                        </div>
                    </div>
                </div>`;
        }
    }

    let allText = `${header}
${chatText}
${footer}`;

    return allText;
}

// ハーメルン用
export function ChatH_Union(chatTitle: string, chatContent: Chat_Content[]) {
    let header = `《box:w100%,bg#273246,bo#273246》
《center》《white》${chatTitle}《/white》《/center》
《box:w100%,bg#7494c0,p0.5》`;
    let footer = `《/box》
《box:w100%,bg#ffffff,p0.5》《left》《text:s1,bg#ffffff》《font:9》＋《/font》《/text》《/left》 《boxbgcolor:#e6e6fa》　《/boxbgcolor》《/box》
《/box》`;
    let chatText = ``;

    for (let chat of chatContent) {
        // 1: 自分, 2: 相手
        if (chat.res_check == 1) {
            chatText += `《right》《box:p0.2,m0.2,bg#8de055,bor0.5》《left》${chat.res_text}《/left》《/box》《/right》`;
            // 0: 未読, 1: 既読
            if (chat.res_read == 1) {
                chatText += `
《right》《text:s0.7》《white》既読《/white》《/text》《/right》`;
            }
        } else {
            chatText += `
《left》《box:m0.2》《text:s0.7》《white》${chat.res_user}《/white》《/text》《/box》《/left》
《left》《box:p0.2,bg#ffffff,bor0.5》${chat.res_text}《/box》《/left》`;
        }
    }

    let allText = `${header}
${chatText}
${footer}`;

return allText;
}