import React from 'react';
import TemplatePage from "./App";
import { Card, ListGroup, Badge, CardBody } from 'react-bootstrap';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const Schedule = () => {
    return (
        <TemplatePage>
            <h2>実装済み報告</h2><small>※過去2週間分</small>
            <Card>
                <Card.Body>
                    <dl>
                    <dt><h4>8/9 実装/お知らせ <Badge bg="danger">New</Badge></h4></dt>
                    <dd><b style={{color: "#dc3545"}}>NEWS</b> - メンテナンスは終了しました。</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - 「CHAT」を実装。LINE風の特殊タグを生成可能</dd>
                    <hr />
                    <dt><h4>8/7 お知らせ</h4></dt>
                    <dd><b style={{color: "#dc3545"}}>NEWS</b> - 8/9 22:00から24:00までの間、新機能実装のためメンテナンスを実施します
                    → <span style={{color: "#dc3545"}}>8/9 終了しました</span></dd>
                    <dd></dd>
                    <hr />
                    <dt><h4>7/27 実装</h4></dt>
                    <dd><b style={{color: "#0d6efd"}}>All</b> - 23:00前後 メンテナンス終了</dd>
                    <dd><b style={{color: "#0d6efd"}}>All</b> - BLOGページ追加、画面下部にお問い合わせ先等のLINKを追加</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - ドロップダウン形式に変更、匿名掲示板以外も選択できるよう修正</dd>
                    <dd><b style={{color: "#0d6efd"}}>Edit</b> - 「CHANGER」を実装。ルビをつけたい単語を複数設定し一括で変更できる</dd>
                    <hr />
                    <dd><b style={{color: "#0d6efd"}}>Simple, Edit</b> - サイドバー(特殊タグをプレビュー)を実装</dd>
                    <dd><b style={{color: "#0d6efd"}}>Simple, Edit, Setting</b> - 固定していたプレビュー画面に固定化のOn/Off切り替えを実装</dd>
                    <dd><b style={{color: "#0d6efd"}}>Setting</b> - プレビュー画面、入力画面のサイズ設定欄、固定化On/Off切り替えの設定を実装</dd>
                    <dd><b style={{color: "#0d6efd"}}></b></dd>
                    <dd><b style={{color: "#0d6efd"}}></b></dd>
                    </dl>

                    <br />
                    <p>すべての履歴は<Link to='./schedule/blog'>BLOG</Link>をご覧ください。</p>
                </Card.Body>
            </Card>

            <br />

            <h2>実装予定</h2>
            <ListGroup>
                <ListGroup.Item>ALL - CODE以外にテキストもコピーできるようにする</ListGroup.Item>
                <ListGroup.Item>Simple - 配信画面風</ListGroup.Item>
                <ListGroup.Item>Simple - 競馬情報配信サイト風</ListGroup.Item>
                <ListGroup.Item>Simple - Wikipedia風</ListGroup.Item>
                <ListGroup.Item>Simple or Edit - LINE風<br />簡易タグかジェネレーター画面を用意するか検討中</ListGroup.Item>
                <ListGroup.Item>その他検討中</ListGroup.Item>
                <ListGroup.Item></ListGroup.Item>
            </ListGroup>
        </TemplatePage>
    );
}

export default Schedule;