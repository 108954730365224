import React, { useState, useEffect, useRef } from 'react';
import TemplatePage from "./App";
import CustomButton from "./Custom/CustomButton";
import CustomTextrare from "./Custom/CustomTextrate";
import CustomTextbox from "./Custom/CustomTextbox";
import Tabs from "./Custom/Tabs";
import { replacements, H_replacements, copy_Item } from "./Custom/Option";
import { Accordion, Offcanvas } from 'react-bootstrap';
import Cookies from 'js-cookie';

// Cookieに保存するためのユーティリティ関数
const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
}

const TestTag = () => {
    const [text, setText] = useState('');
    const [repText, setRepText] = useState('');
    const [h_repText, h_setRepText] = useState('');
    const repTextRef = useRef<HTMLDivElement>(null);
    const hRepTextRef = useRef<HTMLDivElement>(null);
    
    // サイズ等の設定用フック
    const [viewSize, setViewSize] = useState('200');
    const [inputViewSize, setInputViewSize] = useState('180');
    const [previewShow, setPreviewShow] = useState(true);

    // offcanbas用のフック
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // 入力欄に入力されたものをリアルタイムに処理する
    const handleTextChange = (value: string) => {
        setText(value);
        let repCon = replaceContent(value);
        let h_reoCon = H_replaceContent(value);
        setRepText(repCon);
        h_setRepText(h_reoCon);

        setCookie('inputBaseText', encodeURIComponent(value), 30);
        setCookie('VIEW_text', encodeURIComponent(repCon), 30);
        setCookie('CODE_text', encodeURIComponent(h_reoCon), 30);
    };

    // 簡易特殊タグをHTML対応のタグに変換してVIEWに表示する処理
    function replaceContent(text: string): string {
        let output = text;
        replacements.forEach(({ search, replace }) => {
            output = output.replace(new RegExp(search, 'g'), replace);
        });
        return output;
    }

    // 簡易特殊タグをハーメルン対応のタグに変換してCODEに表示する処理
    function H_replaceContent(text: string): string {
        let output = text;
        H_replacements.forEach(({ search, replace }) => {
            output = output.replace(new RegExp(search, 'g'), replace);
        });
        return output;
    }

    // 特殊タグのショートカットボタンが押下された時に走る処理
    const handleAddCode = (label: string) => {
        let tag = label;
        copy_Item.forEach(({ search, replace }) => {
            tag = tag.replace(new RegExp(search, 'g'), replace);
        });

        InputPlus(tag);
    }

    const InputPlus = (tag: string) => {
        const textarea = document.getElementById("floatingTextarea") as HTMLTextAreaElement;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = textarea.value;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        textarea.value = before + tag + after;
        textarea.selectionStart = start + tag.length;
        textarea.selectionEnd = start + tag.length;
        handleTextChange(textarea.value);
    };

    // ハーメルン対応のコードをコピーする処理
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("コピーしました。");
            })
            .catch(err => {
                alert("Failed to copy text to clipboard: 詳細はコンソールログを確認してください。");
                console.error('Failed to copy text to clipboard:', err);
                // エラーハンドリングを行う場合はここで適切に処理を追加します
            });
    };

    const onClick_Copy = () => {
        copyToClipboard(h_repText);
    }

    // VIEWやCODEが常に最新になるようにスクロールする処理
    useEffect(() => {
        if (repTextRef.current) {
            repTextRef.current.scrollTop = repTextRef.current.scrollHeight;
        }
    }, [repText]);

    useEffect(() => {
        if (hRepTextRef.current) {
            hRepTextRef.current.scrollTop = hRepTextRef.current.scrollHeight;
        }
    }, [h_repText]);

    // コンポーネントがマウントされた時にCookieからIDとNameリストを読み込む
    useEffect(() => {
        const storedText = Cookies.get('inputBaseText');
        const storedView_Text = Cookies.get('VIEW_text');
        const storedCodeText = Cookies.get('CODE_text');

        if (storedText) {
            setText(decodeURIComponent(storedText));
        }
        if (storedView_Text) {
            setRepText(decodeURIComponent(storedView_Text));
        }
        if (storedCodeText) {
            let text = decodeURIComponent(storedCodeText);
            h_setRepText(text);
        }

        const storedViewSize = Cookies.get('viewSize');
        const storedInputViewSize = Cookies.get('InputViewSize');
        const storedPreviewShowState = Cookies.get('previewShowState');
        
        if(storedViewSize){
            let size = decodeURIComponent(storedViewSize);
            setViewSize(size);
        }else{
            let baseSize = "200";
            setViewSize(baseSize);
        }
        if(storedInputViewSize){
            let inputSize = decodeURIComponent(storedInputViewSize);
            setInputViewSize(inputSize);
        }else{
            let baseSize = "180";
            setInputViewSize(baseSize);
        }
        if(storedPreviewShowState !== undefined){
            setPreviewShow(decodeURIComponent(storedPreviewShowState) === 'true');
        }else{
            setPreviewShow(true);
        }
    }, []);

    // VIEWとCODEのタブ
    const TABS = [
        {
            id: 'view_text',
            title: 'VIEW',
            content: <CustomTextbox height={viewSize+"px"} value={repText} ref={repTextRef} />,
        },
        {
            id: 'code',
            title: 'CODE',
            content: <CustomTextbox height={viewSize+"px"} value={h_repText} ref={hRepTextRef} />,
        },
    ];

    return (
        <TemplatePage>
            <CustomButton variant="primary" onClick={handleShow}>
                特殊タグをプレビューする
            </CustomButton>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>特殊タグのプレビュー画面</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <div dangerouslySetInnerHTML={{ __html: repText }} ref={repTextRef} />
                </Offcanvas.Body>
            </Offcanvas>

            {!previewShow && <p style={{color: "red"}}>※プレビュー画面の固定化：無効</p>}
            {previewShow && <p style={{color: "green"}}>※プレビュー画面の固定化：有効</p>}
            {previewShow && <Tabs tabs={TABS} />}
            {previewShow && <br/>}
            <div>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>特殊タグ ショートカットボタン</Accordion.Header>
                        <Accordion.Body>
                            <CustomButton onClick={() => handleAddCode(`マシュマロ`)}>マシュマロ風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`ツイッター`)}>ツイッター風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`ニコニコ`)}>ニコニコ大百科風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`うま掲示板`)}>競馬向け掲示板風</CustomButton> {' '}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <br />
                <CustomTextrare height={inputViewSize+"px"} value={text} onChange={handleTextChange} />
            </div>
            <br />
            <CustomButton variant='warning' onClick={onClick_Copy}>コピー</CustomButton>
        </TemplatePage>
    );
};

export default TestTag;