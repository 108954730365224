import React from 'react';
import { Col, Nav, Row, Tab, Tabs as BootstrapTabs } from 'react-bootstrap';

interface TabItem {
  id: string;
  title: string;
  content: React.ReactNode;
  disabled?: boolean; // 追加
}

interface TabsProps {
  vertical?: boolean;
  defaultActiveKey?: string;
  tabs?: TabItem[];
  [key: string]: any;
}

const Tabs: React.FC<TabsProps> = ({
  vertical = false,
  defaultActiveKey,
  tabs = [
    {
      id: 'tab-1',
      title: 'One',
      content: <div>Content 1</div>,
    },
    {
      id: 'tab-2',
      title: 'Two',
      content: <div>Content 2</div>,
    },
    {
      id: 'tab-3',
      title: 'Three',
      content: <div>Content 3</div>,
    },
  ],
  ...otherProps
}) => {

  if (tabs.length <= 0) {
    return null;
  }

  const activeKey = defaultActiveKey || tabs[0].id;

  return vertical ? (
    <Tab.Container defaultActiveKey={activeKey} {...otherProps}>
      <Row>
        <Col sm={3}>
          <Nav variant="underline" className="flex-column">
            {tabs.map((tab: TabItem) => ( // 型アサーションを追加
              <Nav.Item key={tab.id}>
                <Nav.Link 
                  eventKey={tab.id} 
                  href="#" 
                  disabled={tab.disabled} // 追加
                >
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {tabs.map((tab: TabItem) => ( // 型アサーションを追加
              <Tab.Pane key={tab.id} eventKey={tab.id}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  ) : (
    <BootstrapTabs defaultActiveKey={activeKey} justify {...otherProps}>
      {tabs.map((tab: TabItem) => ( // 型アサーションを追加
        <Tab 
          key={tab.id} 
          eventKey={tab.id} 
          title={tab.title} 
          disabled={tab.disabled} // 追加
        >
          {tab.content}
        </Tab>
      ))}
    </BootstrapTabs>
  );
};

export default Tabs;