import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

interface CustomTextrareProps {
    onChange: (value: string) => void;
    value?:string;
    height?:string;
}

const CustomTextrare: React.FC<CustomTextrareProps> = ({ onChange, value, height = "150px" }) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
    };

    return (
        <>
            <FloatingLabel controlId="floatingTextarea" label="Comments">
                <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: height }}
                    value={value}
                    onChange={handleChange}
                />
            </FloatingLabel>
        </>
    );
};

export default CustomTextrare;
