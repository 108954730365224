import React, { useState } from 'react';
import TemplatePage from './App';
import CustomButton from './Custom/CustomButton';
import { ListGroup, Accordion, Card, Button } from 'react-bootstrap'; // React BootstrapからListGroupをインポート
import { replacements, copy_Item } from "./Custom/Option";

// 全体の設定を行う
const Sample = () => {

    const [onButton, setOnButton] = useState("");
    const [explanationList, setExplanationList] = useState([
        {
            label: 'マシュマロ', content: `<p>マシュマロ風のデザインを特殊タグで再現したものです。</p>
簡易タグでは「&lt;マシュマロ&gt;ここに内容&lt;/マシュマロ&gt;」で表現します。` },
        {
            label: 'ツイッター', content: `<p>ツイッター(現X)風のデザインを特殊タグで再現したものです。</p>
<p>簡易タグでは

&lt;ツイッター>
&lt;ユーザー名>ここにユーザー名&lt;/ ユーザー名>
&lt;アカウントID>test_user&lt;/アカウントID>
&lt;ツイート内容>ここに投稿内容です&lt;/ツイート内容>
&lt;/ツイッター>

で表現します。
外側の<ツイッター></ツイッター>以外をコピペすることでTLも作成可能です。</p>` },
        { label: 'ニコニコ', content: `<p>ニコニコ大百科風のデザインを特殊タグで再現したものです。

簡易タグでは

&lt;ニコニコ>
&lt;ニコタイトル>ここに記事タイトル</ニコタイトル>
&lt;ニコフリガナ>ココニキジタイトル</ニコフリガナ>
&lt;ニコ項目目次ヘッタ><ニコ項目目次>目次1</ニコ項目目次><ニコ項目目次>目次2</ニコ項目目次></ニコ項目目次フッタ>
&lt;ニコ項目名>項目１</ニコ項目名>
&lt;ニコ項目内容>ここに内容</ニコ項目内容>
&lt;/ニコニコ>

で表現します。
&lt;ニコ項目名>と&lt;ニコ項目内容>を増やすことで記事内容を追加できます。</p>` },
        { label: 'うま掲示板', content: `<p>某競馬情報サイトの馬ごとの掲示板風のデザインを特殊タグで再現したものです。
            
簡易タグでは

&lt;うま掲示板>
&lt;うま掲示板-タイトル>TEST</うま掲示板-タイトル>
&lt;うま掲示板-レス名前>テスト</うま掲示板-レス名前>
&lt;うま掲示板-レスユーザID>test_user</うま掲示板-レスユーザID>
&lt;うま掲示板-レス内容>ここに内容</うま掲示板-レス内容>
&lt;/うま掲示板>

で表現します。
&lt;うま掲示板-レス名前>、&lt;馬掲示板-レスユーザID>、&lt;馬掲示板-レス内容>をセットで増やすことでレスを追加できます。</p>` },
    ]);

    const [explanation, setExplanation] = useState("");

    const handleAddCode = (label: string) => {
        let tag = label;
        copy_Item.forEach(({ search, replace }) => {
            tag = tag.replace(new RegExp(search, 'g'), replace);
        });

        let output = tag;
        replacements.forEach(({ search, replace }) => {
            output = output.replace(new RegExp(search, 'g'), replace);
        });

        // `label`が一致する`explanationList`の要素を探します
        const foundExplanation = explanationList.find(item => item.label === label);

        // 一致する要素があれば、その`content`を設定します
        const output_E = foundExplanation ? foundExplanation.content : "";


        setOnButton(output);
        setExplanation(output_E);
    }

    return (
        <>
            <TemplatePage>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>対応している特殊タグのサンプル</Accordion.Header>
                        <Accordion.Body>
                            <CustomButton onClick={() => handleAddCode(`マシュマロ`)}>マシュマロ風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`ツイッター`)}>ツイッター風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`ニコニコ`)}>ニコニコ大百科風</CustomButton> {' '}
                            <CustomButton onClick={() => handleAddCode(`うま掲示板`)}>競馬向け掲示板風</CustomButton> {' '}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <br /><br />

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>解説</Accordion.Header>
                        <Accordion.Body>
                        <div style={{whiteSpace: 'pre-wrap', overflowY: 'scroll', }} dangerouslySetInnerHTML={{ __html: explanation || "<p>ここに説明が表示されます</p>" }} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />
                <Card>
                    <Card.Body>
                        <div dangerouslySetInnerHTML={{ __html: onButton }} />

                    </Card.Body>
                </Card>
            </TemplatePage>
        </>
    )
}

export default Sample;
